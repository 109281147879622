// src/routes.tsx

import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AuthContext } from "./context/AuthContext";

import PrivateRoute from "./components/PrivateRoute";
import { LayoutWithNavbar } from "./components/LayoutWithNavbar";
import ScrollToTop from "./components/ScrollToTop";

import NotFound404 from "./pages/NotFound404";
import Termos from "./pages/Termos";
import Privacidade from "./pages/Privacidade";
import Help from "./pages/Help";
import LandingPage from "./pages/LandingPage";

const AppRoutes = () => {
  const authContext = useContext(AuthContext);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
