import styled from "styled-components";
const bgBanner = require("../../assets/images/bg-banner.png");
const imgBanner = require("../../assets/images/img-banner-lp.png");

export const Banner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    padding: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebebeb;
    background-image: url(${bgBanner});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const BannerSide = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 577px;
    align-items: center;
    justify-content: center;
`;

export const BannerImgRight = styled.div`
    width: 100%;
    height: 50vh;
    background-image: url(${imgBanner});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 230%;
`;

export const BannerAreaText = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BannerTitle = styled.h1`
    color: #161616;
    font-size: 50px;
    margin-bottom: 0;
`;

export const BannerDescr = styled.p`
    color: #161616;
    font-size: 27px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 1154px;
`;

export const BasicSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;
  text-align: center;
  min-height: 40vh;
`;

export const BasicSectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #009465;
  margin-bottom: 0px;
  margin-top: 0;
`;

export const BasicSectionSubtitle = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 30px;
`;

export const GreenSection = styled.section`
  padding: 40px 0;
  text-align: center;
  background-color: #009465;
`;

export const GreenSectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 0;
`;

export const GreenSectionSubtitle = styled.p`
  font-size: 1.25rem;
  color: #fff;
  margin-bottom: 30px;
`;

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Step = styled.div`
  flex: 1;
  max-width: 300px;
  text-align: left;
  border: 2px dashed #009465;
  padding: 20px;
  border-radius: 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const StepTitle = styled.h3`
  font-size: 1.5rem;
  color: #009465;
  margin-top: 0;
  margin-bottom: 0;
`;

export const StepDescription = styled.p`
  font-size: 1.1rem;
  color: #666;
  margin-top: 2px;
  margin-bottom: 0;
`;

export const Features = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  color: #009465;
  margin-top: 0;
`;

export const Feature = styled.div`
  flex: 1;
  max-width: 250px;
  text-align: left;
  border: 1px solid #009465;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 7px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Testimonials = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Testimonial = styled.div`
  flex: 1;
  max-width: 300px;
  font-style: italic;
`;

export const Author = styled.p`
  font-weight: bold;
  margin-top: 10px;
`;

export const Pricing = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Plan = styled.div`
  flex: 1;
  max-width: 200px;
  text-align: left;
  padding: 20px;
  border: 1px solid #ccc;
  margin: 0 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const PlanTitle = styled.h3`
  font-size: 1.5rem;
  color: #333;
`;

export const PlanPrice = styled.p`
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 10px;
`;

export const FinalCTA = styled.section`
  padding: 60px 0;
  background-color: #f4f4f4;
  text-align: center;
`;

export const CTAButton = styled.button`
  padding: 15px 30px;
  background-color: #009465;
  color: white;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
`;