import styled from "styled-components";
const logo = require("../../assets/images/logo-name.png");

export const Container = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0px;
  box-sizing: border-box;
  background-color: #fff;
  color: #fff;
  border-bottom: 1px solid #ebebeb;
`;

export const Content = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  max-width: 1200px;
  box-sizing: border-box;
`;

export const Logo = styled.div`
  width: 120px;
  height: 30px;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  cursor: pointer;

  @media (max-width: 750px) {
    //display: flex;
  }
`;

export const AreaBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const BtnWhite = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #009465;
  background-color: #fff;
  padding: 10px 27px;
  font-size: 16px;
  border-radius: 27px;
  color: #009465;
  font-weight: bold;
  cursor: pointer;
`;

export const BtnGreen = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 0;
  background-color: #009465;
  padding: 10px 27px;
  font-size: 16px;
  border-radius: 27px;
  margin-left: 10px;
  font-weight: bold;
  cursor: pointer;
`;
