import React, { useEffect, useState } from "react";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import AppRoutes from "./routes";

import themeMaterial from "./context/ThemeMaterialUi";
import { getUrl } from "./Utils/DefineUrls";

function App() {
  return (
    <MuiThemeProvider theme={themeMaterial}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </MuiThemeProvider>
  );
}

export default App;
