import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../../components/Navbar";
import {
  Container,
  Content,
  Banner,
  BannerAreaText,
  BannerDescr,
  BannerImgRight,
  BannerSide,
  BannerTitle,
  BasicSection,
  BasicSectionTitle,
  BasicSectionSubtitle,
  Feature,
  FeatureTitle,
  Features,
  Author,
  CTAButton,
  FinalCTA,
  Plan,
  PlanPrice,
  PlanTitle,
  Pricing,
  Step,
  StepDescription,
  StepTitle,
  Steps,
  Testimonial,
  Testimonials,
  GreenSection,
  GreenSectionTitle,
  GreenSectionSubtitle,
} from "./styles";

const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Slothlypay - Venda e Gerencie Seus Infoprodutos com Facilidade
        </title>
        <meta
          name="description"
          content="A Slothlypay é a plataforma ideal para criar, vender e gerenciar infoprodutos de maneira simples e eficaz. Ofereça seus cursos online, e-books, e treinamentos digitais com todas as ferramentas que você precisa em um só lugar. Experimente agora e potencialize suas vendas!"
        />

        <meta
          name="keywords"
          content="plataforma de infoprodutos, venda de infoprodutos, cursos online, e-commerce de infoprodutos, Slothlypay, Slothly, Kiwify, Hotmart, vender infoprodutos, monetização digital, gestão de infoprodutos, pagamentos online, marketing digital, venda de cursos, produtos digitais, criadores de conteúdo, treinamentos online, automação de vendas, conversão digital, afiliados, plataformas de afiliados"
        />
        <meta name="author" content="Slothlypay" />
        <meta
          property="og:title"
          content="Slothlypay - Venda e Gerencie Seus Infoprodutos com Facilidade"
        />
        <meta
          property="og:description"
          content="A Slothlypay é a plataforma que facilita a criação e venda de infoprodutos. Maximize suas vendas com soluções completas para cursos online, e-books e mais."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.slothlypay.com" />
      </Helmet>
      <Navbar />
      <Banner>
        <BannerSide>
          <BannerAreaText>
            <BannerTitle>Transforme Seu Conhecimento em Receita </BannerTitle>
            <BannerDescr>
              Venda e distribua seus conteúdos digitais de forma simples e
              eficaz.
            </BannerDescr>
          </BannerAreaText>
        </BannerSide>
        <BannerSide>
          <BannerImgRight />
        </BannerSide>
      </Banner>
      <GreenSection>
        <Content
          style={{
            margin: "0 auto",
          }}
        >
          <GreenSectionTitle>
            Venda aprovada é lucro garantido
          </GreenSectionTitle>
          <GreenSectionSubtitle>
            Conte com um sistema de pagamento eficiente que assegura mais de 90%
            de aprovação, potencializando seus resultados financeiros.
          </GreenSectionSubtitle>
          <GreenSectionSubtitle>
            Uma venda não aprovada afeta dois lados: o seu, como produtor que
            perde a oportunidade de lucrar, e o do cliente, que deixa de receber
            o produto que tanto quer.
          </GreenSectionSubtitle>
        </Content>
      </GreenSection>
      <Container>
        <Content>
          <BasicSection>
            <BasicSectionTitle>Como Funciona?</BasicSectionTitle>
            <BasicSectionSubtitle>
              Crie, publique e venda em poucos passos
            </BasicSectionSubtitle>
            <Steps>
              <Step>
                <StepTitle>Passo 1</StepTitle>
                <StepDescription>Crie Seu Produto Digital</StepDescription>
                <p>Suba seu curso, ebook, ou vídeo de forma rápida.</p>
              </Step>
              <Step>
                <StepTitle>Passo 2</StepTitle>
                <StepDescription>Configure Seu Pagamento</StepDescription>
                <p>Escolha seus preços e métodos de pagamento preferidos.</p>
              </Step>
              <Step>
                <StepTitle>Passo 3</StepTitle>
                <StepDescription>Comece a Vender</StepDescription>
                <p>Compartilhe seu link de venda e veja os resultados.</p>
              </Step>
            </Steps>
          </BasicSection>
        </Content>
      </Container>

      <GreenSection>
        <Content
          style={{
            margin: "0 auto",
          }}
        >
          <GreenSectionTitle>Segurança em cada transação</GreenSectionTitle>
          <GreenSectionSubtitle>
            A confiança é fundamental para o sucesso de qualquer venda online.
            Com nosso sistema robusto, você garante que cada transação seja
            segura tanto para você quanto para o seu cliente, eliminando riscos
            e aumentando a credibilidade do seu negócio. Deixe as preocupações
            para trás e foque apenas em escalar suas vendas.
          </GreenSectionSubtitle>
        </Content>
      </GreenSection>

      <Container>
        <Content>
          <BasicSection>
            <BasicSectionTitle>
              Funcionalidades que Aceleram Suas Vendas
            </BasicSectionTitle>
            <BasicSectionSubtitle>
              Tudo o que você precisa para maximizar suas conversões
            </BasicSectionSubtitle>
            <Features>
              <Feature>
                <FeatureTitle>Gestão Simples de Conteúdo</FeatureTitle>
                <p>Organize seus infoprodutos com facilidade.</p>
              </Feature>
              <Feature>
                <FeatureTitle>Integração com Pagamentos Seguros</FeatureTitle>
                <p>Suporte para cartão de crédito, boleto e mais.</p>
              </Feature>
              <Feature>
                <FeatureTitle>Automação de E-mails</FeatureTitle>
                <p>Envie e-mails automatizados para seus clientes.</p>
              </Feature>
              <Feature>
                <FeatureTitle>Suporte ao Cliente</FeatureTitle>
                <p>
                  Tire dúvidas e resolva problemas com nosso suporte dedicado.
                </p>
              </Feature>
            </Features>
          </BasicSection>
        </Content>
      </Container>

      <GreenSection>
        <Content
          style={{
            margin: "0 auto",
          }}
        >
          <GreenSectionTitle>Automação que trabalha por você</GreenSectionTitle>
          <GreenSectionSubtitle>
            Tempo é dinheiro, e com nosso sistema de automação, você ganha
            ambos. Automatize tarefas repetitivas, como o envio de e-mails
            pós-venda e notificações aos clientes, e foque no que realmente
            importa: fazer seu negócio crescer. Deixe a tecnologia fazer o
            trabalho pesado enquanto você vê seus resultados aumentarem.
          </GreenSectionSubtitle>
        </Content>
      </GreenSection>

      <Container>
        <Content>
          <BasicSection>
            <BasicSectionTitle>O que Nossos Clientes Dizem</BasicSectionTitle>
            <BasicSectionSubtitle>
              Veja como estamos transformando negócios digitais
            </BasicSectionSubtitle>
            <Testimonials>
              <Testimonial>
                <p>"Já estava cansado de bloqueios em outras plataformas!"</p>
                <Author>– João Silva</Author>
              </Testimonial>
              <Testimonial>
                <p>"A plataforma revolucionou meu negócio!"</p>
                <Author>– Lucas Silva</Author>
              </Testimonial>
              <Testimonial>
                <p>"Simples, prática e muito eficiente."</p>
                <Author>– Maria Oliveira</Author>
              </Testimonial>
            </Testimonials>
          </BasicSection>
        </Content>
      </Container>

      <GreenSection>
        <Content
          style={{
            margin: "0 auto",
          }}
        >
          <GreenSectionTitle>Tá esperando o quê?</GreenSectionTitle>
          <GreenSectionSubtitle>
            Não deixe oportunidades escaparem! Cada dia que passa é uma chance a
            mais de impulsionar suas vendas e maximizar seus lucros. Com nosso
            sistema eficaz e fácil de usar, você pode começar a ver resultados
            rapidamente. Entre para o time dos vencedores e transforme suas
            ideias em lucros. O sucesso espera por você, não perca mais tempo!
          </GreenSectionSubtitle>
        </Content>
      </GreenSection>

      <Container>
        <Content>
          <BasicSection>
            <BasicSectionTitle>Taxas e prazos</BasicSectionTitle>
            <BasicSectionSubtitle>
              Ideal para quem está começando
            </BasicSectionSubtitle>
            <Pricing>
              <Plan>
                <PlanTitle>Taxas de vendas</PlanTitle>
                <PlanPrice>4.99% + R$ 1,49</PlanPrice>
                <p>Por transação aprovada.</p>
              </Plan>
              <Plan>
                <PlanTitle>Taxas de saque</PlanTitle>
                <PlanPrice>R$ 2,99</PlanPrice>
                <p>Por saque solicitado.</p>
              </Plan>
              <Plan>
                <PlanTitle>Prazos para sacar</PlanTitle>
                <PlanPrice>
                  D+0 PIX <br /> D+14 Cartão <br /> D+2 Boleto
                </PlanPrice>
                <p>
                  Com opção de solicitar D+2 cartão, após os primeiros 30 dias
                  de vendas.
                </p>
              </Plan>
            </Pricing>
          </BasicSection>
        </Content>
      </Container>
      <FinalCTA>
        <BasicSectionTitle>Pronto para Começar?</BasicSectionTitle>
        <BasicSectionSubtitle>
          Venda seus infoprodutos e alcance mais clientes com nossa plataforma.
        </BasicSectionSubtitle>
        <a href="https://app.slothlypay.com/register">
          <CTAButton>Crie sua Conta Gratuitamente</CTAButton>
        </a>
      </FinalCTA>
    </>
  );
};

export default LandingPage;
