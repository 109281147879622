import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { Container as Content } from "@mui/material";

import { AuthContext } from "../../context/AuthContext";

import { Container, Logo, AreaBtn, BtnWhite, BtnGreen } from "./styles";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    //
  }, []);

  if (isLoading) {
    return (
      <Modal
        open={isLoading}
        onClose={() => false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Modal>
    );
  }

  function handleGoHome() {
    navigate("/");
  }

  return (
    <>
      <Container>
        <Content
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Logo onClick={handleGoHome} />
          </div>
          <AreaBtn>
            <a href="https://app.slothlypay.com/login">
              <BtnWhite>Login</BtnWhite>
            </a>
            <a href="https://app.slothlypay.com/register">
              <BtnGreen>Cadastre-se</BtnGreen>
            </a>
          </AreaBtn>
        </Content>
      </Container>
    </>
  );
};

export default Navbar;
